import React from "react"

import "./blog.css"

const Blog = () => {
  return (
  <div>
	<ol>
	<li>Agents make decisions based off of estimated rewards and/or punishments (henceforth incentives) in the long run</li>
	<li>Decisions can affect incentives some where or some time else</li>
	<li>Games are defined as situations where outcomes (realization of incentives) are decided by more than one agent</li>
	<li>Incentives are always incomplete temporally and/or spatially with respect to what they can affect (henceforth scope of incentives)</li>
	<li>Estimating incentives is itself a game, specifically when "learning" the game</li>
	<li>Faith is the foundation in how people estimate their incentives, i.e. confidence in the estimated incentives</li>
	<li>"Structures" arise from incentive estimation, e.g. rules, generalizations, exceptional cases, etc., that represent "safe" assumptions about incentives</li>
	<li>Agents enter games willingly if they have high faith and estimated positive outcomes</li>
	<li>Agents reduce scope of incentives when there are no estimated positive outcomes or lack of faith, resulting in short term and narrow focus</li>
	<li>When many agents in many games only think short term and narrow focus, tragedy of the commons occurs</li>
	<li>Tragedies affect other games, adding more risk to incentive estimations</li>
	<li>See #9</li>
	</ol>
  </div>
  ) || (
    <div>
      <h2>October 22, 2023</h2>
      <p>13 years later, I felt that I had left more than just my rifle in the desert.</p>
      <p>I was not quite sure what it was. Maybe something like innocence. Wonder. Hope.</p>
      <p>When I rejoined society, I focused on moving up the socioeconomic ladder.</p>
      <p>I'm not a ruthless person but that dog had to be fed and unleashed, and the first person I sicced it on was me.</p>
      <p>The dog herded me towards cynical pragmatism. Keep as many doors open as possible. Always have an escape hatch. Never commit. Never again.</p>
      <p>But year after year, I secretly longed for the desert.</p>
      <p>My wife tells me the few times I speak about that life, I have nightmares that very same night. Like clockwork.</p>
      <p>But desert life was simple. Live one day at a time, like it would be your last. Live because the desert had an end. Eventually, you abandon a part of your self, or you die there.</p>
      <p>I got married 8 days ago.</p>
      <p>It was a rush of 96 hours. I averaged maybe 3-4 hours of sleep per night.</p>
      <p>But I was alive. I didn't work. I didn't read. I didn't prepare for the future or seek new opportunities or escape hatches.</p>
      <p>I lived those days like they would be the last. Because they likely were. I lived it, one day at a time. I lived, because I knew it would end.</p>
      <p>And when I made my vows, and committed again after nearly two decades, it became clear to me what I was missing.</p>
      <p>I had forgotten what it was like to be afraid of the end.</p>
      <p>I'm afraid of time running out. I'm afraid of losing my wife. I'm afraid of losing my body, my health, my mind. I'm afraid I won't get more chances to tell people I care for them deeply. I'm afraid I won't get the chance to shoot my shot, write my stories, tell my tale, and share life, because I've had plenty.</p>
      <p>I had lost my faith for life. That the commitment is worth it. That the way to be alive is to live with an end. Either it changes you, or it kills you. And my marriage and vows have given it back to me. I welcome the future change. And I hope I will die with it.</p>
      <p>The ocean is flat, but I do not need the thrill of a storm to be alive. Love, life, and the end, is enough.</p>
  
      <br></br><br></br><br></br><br></br>
        
        <h2>August 22, 2021</h2>
        <p>After 4.5 years at Better Mortgage, I left in May 2021.</p>

        <p>During those years, I, out of lack of better phrasing, evolved. I'm eternally grateful for the experience. Joining as the 15th engineer and seeing it grow to hundreds (and the rest of the company thousands) was a once in a lifetime experience. I couldn't be happier with the people I've built relationships with and learned from.</p>

        <p>Some personal and professional things I've learned and internalized in no particular order:</p>
        <ul>
            <li> The barrier between personal and professional is entirely constructed. Take control of that construction and allow it to blend or separate depending on the context</li>
            <li>You're always coding for at least two people: yourself today and yourself a year from now. Be nice to your future self and be gracious to your past self</li>
            <li>Pain is a great way to discourage behaviors (think of it as forming propositions in a language with negations as opposed to without) but trust and faith is what guides and reinforces behaviors</li>
            <li>The majority of technical debt is incurred from organizational costs. Limited time, limited resources, limited information, etc. Not many engineers wake up in the morning ecstatic to add future costs to themselves, their team, and the business </li>
            <li>Nobody knows what's going on, no matter what they say or think. Everyone, on ask, has some n-featured model that was assembled on the spot randomly that barely approximates what will happen next. Higher n does not imply a better model</li>
            <li>Write as little code as possible</li>
            <li>Software engineers are modern day wizards. Similar to the tropes, there are very few wizards that cross train. The ones that do are highly valuable. Also similar to the tropes, they are highly fragile and complain relentlessly under the slightest of imperfect conditions</li>
            <li>Technology deletes (via efficiency) or replaces (via effectiveness) jobs. Let's all just start being honest about it</li>
            <li>Software engineers have no power to collectively bargain but corporations have openly formed cartels (and sometimes been caught). This should speak to how intelligence does not mean wisdom</li>
            <li>Video games are hard. No, really, they are hard</li>
            <li>If an engineer makes a mistake and couldn't see it coming, it's probably a systematic problem and not an individual one</li>
            <li>The javascript ecosystem is hot garbage</li>
            <li>Python's typing attempt is way too immature and somewhat disappointing in achievement and direction</li>
            <li>Nobody cares about your application code performance. As you scale it's more likely your shared resources that will be tanked, e.g. your database, your messaging pipes, etc. and these will be catastrophic</li>
            <li>Never back yourself into a corner. Always have an escape hatch, procedural or codewise</li>
            <li>People are conflict averse. Learn to be conflict loving so you can break bad behaviors with pain, but you also have to be nurturing and kind to encourage new ones. Most people can barely do one, let alone both</li>
            <li>Love really is the only way</li>
            <li>There is very rarely a truly victimless crime</li>
            <li>Institutional knowledge usually only gets measured by how much pain is caused on a team after someone leaves. By then it's too late and can cause a dead sea effect</li>
            <li>Your coding setup should be made to maximize brain to computer bandwidth</li>
            <li>There are no bad software engineers, merely untrained ones. It's just that nobody wants to train them, both at the individual level and the corporate level. Training requires practical (external) and personal (internal) work, and that's a risk most places aren't willing to take. That's why we have a bimodal and long tail distribution of engineering pay/outcomes</li>
            <li>Success can bring defeat if unguarded from those who seek success. When we were missing targets we rallied together and we became more divided the moment we began succeeding. The influx of resume chasers made it even worse</li>
            <li>Fullstack engineers should be paid a lot more than what they get paid. They know everything from the web layer all the way down to interservice communication works and often dive into database problems. We can now throw on all the 'platform' stuff too like K8s, Docker, etc.</li>
            <li>Your data is everything. All code should be dependent on the data</li>
            <li>If you have to ask if it's worth it, it's probably not</li>
            <li>Try to remember what the beliefs of the company are as it starts to succeed. The contrast can be jarring</li>
            <li>Your work affects society. Diffusion of responsibility is a nonsense excuse</li>
            <li>Yesterday, we woke up and we were 25. Tomorrow we will wake up and be 50. Don't let your life pass you by like a dream</li>
        </ul>
        <br></br><br></br><br></br><br></br>
        <h2>February 24, 2019</h2>
        <p>To be at peace with oneself and God is a vague omni-virtue that seems to unite all beliefs. It's akin to the stoic temperament in practice, and is universally respected. From Job's trials to the stoic Meditations to the Buddhist response and expectations of suffering all the way to the Camusian absurdist defiance, to turn one's cheek and confront our powerlessness is ultimately something that religion, philosophy, and whatever else aims to do.</p>
        <p>And I've been thinking about it a lot. I've been chasing peace with myself for along time, and in the past few years, I've had to make more peace with God. Not the Abrahamic God mind you, but 'God' as in the greater powers that be. You know... gravity, physics, people. Basically, anything outside of my control, and it's very easy to debate if I really have any control at all. It waxes and wanes like the tide, and just like the tide, 'it' cannot be stopped. Making peace with that is something I suspect I will have to work harder on as I grow older, and perhaps it will manifest into a respect and awe not unlike that of the Nietzchean child.</p>
        <p>My teenage years have left me with hints of angst, despair, and defiance all wrapped into a tiny little bundle deeply planted in my heart, and while I have done a great job at molding it into something productive, it is there, and these topics come to my mind regularly. That is the making peace with myself part. To live with it, ironically, is to consider one's death and put an end as the ultimate objective in life. Some kind of fucked up uninformed death terror-management-theory sort of dream. But I do what I can, and I try not to rock the boat of it too much at any given point.</p>
        <p>Recently I have come upon a new angle that is more concerning than I have voiced out loud.</p>
        <p>What if my, our, premise is wrong? What if the numinous qualities of godhood are, like all magic of ages prior, is a curtain to be pulled back upon?</p>
        <p>Before I write more about that, I'd like to point out that most of our morality and ethics is predicated on our question of: 'what is the meaning of life', and there are arguable proofs that there isn't a set of logical answers. Entire religions, cultures, even nations, have been built off of the response to that question, each more adamant than the last that they have an answer.</p>
        <p>What if the correct answer is,</p>
        <p>“We don't know...yet.”</p>
        <p>Our insight into ourselves and nature has grown exponentially over the years. 200 years ago, nobody thought we could fly. 2000 years ago, nobody could have imagined that 'things that were' would ever change, and that the Roman empire would live forever. We're so used to small scale thinking that we never factor it into our current day thoughts and adjusting our heuristics just to keep doors open.</p>
        <p>If you accept the prior paragraph, then how does it adjust your thinking? For me, it changes everything. Our directive is not to 'survive' or 'adapt' or any of those things in isolation. All our guns should point in a single direction of ensuring the next generation has just as much of a chance if not greater to find out the answer to the first, last, and only important question.</p>
        <p>This messes all sorts of things up inside. Everything I wrote in the first paragraph is incorrect. There is no peace – there is only the war to fight for the future generations to get a chance at the last question.</p>
        <p>To complicate things further still, we are on the verge of placing our hand on the curtain of the numinous. What if godhood being outside the reach of man isn't tautological? What if godhood is something to be achieved and not merely desired?</p>
        <p>Think long term for a minute. Suppose we dedicated all of our worldly resources into the singular purpose of hacking our own biology, and then hacking our own minds with electronics.</p>
        <p>How many years, in orders of magnitude, would you think it would take before we could upload ourselves into a machine and dispatch new bodies at will? It sounds ludicrous, but do you have any evidence whatsoever that it is impossible? There is overwhelming evidence that it IS possible, and our biggest obstacle is understanding how our consciousness work. Cartesian duality has been a mystery for centuries, but how long do you think it will be, IF we had the resources, to crack that code?</p>
        <p>A lot of people have this allergic response to these ideas at this juncture. “You don't know if it's possible” or “that sounds imaginary”. But you literally have no evidence that it's impossible, but minor evidence that it is, and yet we default to a reclusive rejection of new possibilities. It's this lag in mentality that keeps fucking us, from tribalism, to human rights, to the general breakdown of progression. In other words, Clarke's first law is in full effect and rarely challenged, yet our cognitive dissonance prevents us from embracing its implications.</p>
        <p>So then if we 1) accept that we don't know the answer to our existence and 2) the future is endless, our orientation should be clear:</p>
      <p>“Move forward at all costs”</p>
      <p>If this is the guiding mantra of all our individual actions, a very black and white and consistent system of morals and ethics arises. Death is nothing to be feared or shy away from, empathy and open mindedness is a hard requirement to maximize 'forward' movement, and people have intrinsic value by how much they can move the needle. Keep in mind “forward” is arbitrary – with n dimensions we can move in, that's n different ways we could move 'forward' in isolation. In other words, people should always 'adopt' the new and retreat back when it prevents a new 'new'.</p>
      <p>It's not all ponies and rainbows. All sorts of bad shit could happen in the face of progress. Cat people, economic and social inequality (imagine that godhood with the machine cost you some amount that only a certain class of people could afford), mass depression and existential crises (how many people can actually move the needle on their own? Very few. Imagine living under the impression you are intrinsically worth nothing more than another helping hand and not hero protagonist of your own adventure).</p>
      <p>But it solves, in the age of normalization of recidivism and nuclear weapons, our biggest problem. The massive conflicts we have when we try to answer the last question. Instead, we would orient ourselves with a 'meta rule' that basically says: 'go forth and seek out new rules that bring us closer to “the rule to rule them all”'. Our existential threats would be abated, and we would make use of natural selection at its highest form in both biology and memes, since every 'frontier' we search out will succeed or fail based off of the trajectory it moves us towards.</p>
      <p>This all arose from the simple idea that sometimes, answers are undefined, as opposed to being a 'null' value (absent of a value), or even a real one. Rather than flee in the face of uncertainty and leap eagerly to the closest solution, we should acknowledge the unknown for what it is: merely not known...yet.</p>
      <p>At the end of it, whether I am indeed at peace with myself or with God, or not, I have to imagine we have a future that we will attain at some point. If I am not, or cannot help there, then I must maintain my peace. If I can... then, like all of us, I have work to do.</p>
    </div>
)
}

Blog.propTypes = {}

export default Blog
